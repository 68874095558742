import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { _getInputControls } from '@utility/utility';
import { Subject, combineLatest, takeUntil, tap } from 'rxjs';
@Component({
    selector: 'selected-custom',
    templateUrl: 'selected-custom.component.html',
    styleUrls: ['selected-custom.component.scss'],
})
export class SelectedCustomComponent implements OnInit, OnChanges, OnDestroy {
    createRows = [];
    setToDefaults: boolean = false;
    isPatientOverviewUrl: boolean = false;
    isPatientAllUrl: boolean = false;
    isManageViewUrl: boolean = false;
    isCrcSiteUrl: boolean = false;
    isAdminSiteUrl: boolean = false;

    private genericRange = {};
    private selectControls: {}[];
    private patientId: number;
    private filters: {};
    private componentDestroyed$: Subject<boolean> = new Subject();
    private queryFilters = {};
    private filtersModifiedByUser: boolean = false;

    @Input() data: any[];
    @Output() filterClickEvent = new EventEmitter();
    @Output() resetDataClickEvent = new EventEmitter();
    allOption = {
        val: 'all',
        viewValue: 'ALL',
    };
    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        combineLatest({ params: this.route.params, queryParams: this.route.queryParamMap })
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((result) => {
                    this.patientId = result?.params?.patientId;
                    this.queryFilters = {};

                    if (result?.queryParams?.keys.length !== 0) {
                        const params = JSON.parse(result?.queryParams?.get('params'));
                        const statusFilters = params['status'];
                        if (statusFilters) {
                            this.queryFilters['status'] = statusFilters;
                        }
                        this.genericRange = this.queryFilters;
                    }
                    this.isPatientAllUrl = this.router.url.includes('admin/patients');
                    this.isManageViewUrl = this.router.url.includes('superadmin/manage');
                    this.isAdminSiteUrl = this.router.url.includes('admin/sites');
                    this.isCrcSiteUrl = this.router.url.includes('crc/sites');

                    if (!this.isPatientAllUrl && !this.isManageViewUrl) {
                        this.isPatientOverviewUrl = this.router.url.indexOf('patients') > -1;
                    }

                    this.setFilters();
                })
            )
            .subscribe();
    }
    ngOnDestroy(): void {
        if (this.filtersModifiedByUser) {
            const dataToStore = { ...this.genericRange, id: this.patientId };

            localStorage.setItem(
                this.getFilterName(),
                Object.keys(dataToStore).length > 0 ? JSON.stringify(dataToStore) : null
            );
        }

        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    getLocalStorageData() {
        const storeData = JSON.parse(localStorage.getItem(this.getFilterName()));

        if (this.isPatientOverviewUrl && storeData?.id !== this.patientId) return null;
        return storeData;
    }

    setFilters() {
        this.filters = this.isEmpty(this.queryFilters) ? this.getLocalStorageData() : this.queryFilters;
        if (this.selectControls) {
            this.selectControls.forEach((element) => {
                element['selectedValues'] = [];
            });

            if (this.filters) {
                let arrayKey = Object.keys(this.filters);
                arrayKey.forEach((key) => {
                    let baseKey = key;
                    if (key === 'requesttype') key = 'request type';
                    if (key === 'visitname') key = 'visit name';
                    if (key === 'visitdate') key = 'visit date';
                    if (key === 'requestdate') key = 'request date';
                    if (key === 'patientNum') key = 'patient id';
                    if (key === 'statusid') key = 'status';
                    if (key === 'requesttimestamp') key = 'request timestamp';
                    if (key === 'closetimestamp') key = 'close timestamp';
                    if (key === 'processedby') key = 'processed by';

                    let data = this.selectControls.find((x) => x['labelText'].toLowerCase() === key);
                    if (data) {
                        if (
                            data['values'] !== null &&
                            data['values'].some((x) => this.filters[baseKey].includes(x.value))
                        ) {
                            data['selectedValues'] = this.filters[baseKey];
                        }
                    }
                });
            } else {
                this.selectControls.forEach((element) => {
                    if (element['defaultSelectedValues'] != undefined)
                        element['selectedValues'] = element['defaultSelectedValues'];
                });
            }
        }
    }

    /* istanbul ignore next */
    ngOnChanges(changes: SimpleChanges) {
        this.isManageViewUrl = this.router.url.includes('superadmin/manage');

        if (changes.data && changes.data.currentValue?.length > 0) {
            let controls = _getInputControls('');
            if (this.isPatientAllUrl) {
                controls = _getInputControls('patientAll');
                const statusControl = controls?.find((p) => p['labelText'] === 'Status');
                let countryControl = controls?.find((p) => p['labelText'] === 'Country');
                countryControl['values'] = Array.from(
                    new Set(this.data?.filter((item) => item.country !== 'Removed').map((item) => item.country))
                ).map((el) => ({
                    value: el,
                    viewValue: el,
                }));
                //status has patient data
                statusControl['values'] = [...new Set(this.data?.map((item) => item.statusId))].map((el) => ({
                    value: el,
                    viewValue: this.data?.find((val) => val.statusId === el).statusName,
                }));
            } else if (this.isManageViewUrl) {
                controls = _getInputControls('accessRequests');

                const statusControl = controls?.find((p) => p['labelText'] === 'Status');
                const accessTypeControl = controls?.find((p) => p['labelText'] === 'Access Type');
                const processedByControl = controls?.find((p) => p['labelText'] === 'Processed By');
                statusControl['values'] = [...new Set(this.data?.map((item) => item.status))].map((el) => ({
                    value: el,
                    viewValue: el,
                }));
                accessTypeControl['values'] = [...new Set(this.data?.map((item) => item.accessType))].map((el) => ({
                    value: el,
                    viewValue: el,
                }));
                processedByControl['values'] = [...new Set(this.data?.map((item) => item.processedBy))].map((el) => ({
                    value: el,
                    viewValue: el,
                }));
            } else if (this.isCrcSiteUrl || this.isAdminSiteUrl) {
                controls = _getInputControls('crcSites');

                const statusControl = controls?.find((p) => p['labelText'] === 'Status');
                let countryControl = controls?.find((p) => p['labelText'] === 'Country');
                countryControl['values'] = Array.from(
                    new Set(this.data?.filter((item) => item.country !== 'Removed').map((item) => item.country))
                ).map((el) => ({
                    value: el,
                    viewValue: el,
                }));
                //status has patient data
                statusControl['values'] = [...new Set(this.data?.map((item) => item.status))].map((el) => ({
                    value: el,
                    viewValue: el,
                }));
            } else {
                let visitNameControl = controls?.find((p) => p['labelText'] === 'Visit Name');
                let requestTypeControl = controls?.find((p) => p['labelText'] === 'Request Type');
                const statusControl = controls?.find((p) => p['labelText'] === 'Status');

                visitNameControl['values'] = Array.from(new Set(this.data?.map((item) => item.visitName))).map(
                    (el) => ({
                        value: el,
                        viewValue: el,
                    })
                );

                requestTypeControl['values'] = Array.from(new Set(this.data?.map((item) => item.requestType))).map(
                    (el) => ({ value: el, viewValue: el })
                );

                if (!this.isPatientOverviewUrl) {
                    controls = _getInputControls('requests');
                    let patientControl = controls?.find((p) => p['labelText'] === 'Patient ID');
                    let protocolControl = controls?.find((p) => p['labelText'] === 'Protocol');

                    patientControl['values'] = Array.from(new Set(this.data?.map((item) => item.patientNum))).map(
                        (el) => ({
                            value: el,
                            viewValue: el,
                        })
                    );
                    protocolControl['values'] = Array.from(new Set(this.data?.map((item) => item.protocol))).map(
                        (el) => ({
                            value: el,
                            viewValue: el,
                        })
                    );
                    visitNameControl['values'] = Array.from(new Set(this.data?.map((item) => item.visitName))).map(
                        (el) => ({ value: el, viewValue: el })
                    );

                    controls = controls?.filter((f) => f['type'] != null);
                    if (!this.isLargeScreen()) {
                        controls.splice(5, 0, {
                            labelText: '',
                            type: null,
                            category: '',
                            placeholderText: '',
                            values: null,
                        });
                    }
                }
                //status has request data
                statusControl['values'] = [...new Set(this.data?.map((item) => item.status))].map((el) => ({
                    value: el,
                    viewValue: el,
                }));
            }

            this.selectControls = controls;
            this.setFilters();

            this.createColumnsForFilterControls();
        }
    }

    isLargeScreen() {
        return window.innerWidth > 1200;
    }

    applyFilters(): void {
        let dataObject = this.separateObject(this.genericRange);

        this.filterClickEvent.emit(dataObject);
    }

    onApplyFiltersClicked() {
        if (this.isEmpty(this.genericRange)) return;

        this.filtersModifiedByUser = true;
        this.applyFilters();
    }

    private isEmpty(obj: Object): boolean {
        return Object.getOwnPropertyNames(obj).length === 0;
    }
    onResetFiltersClicked() {
        this.setToDefaults = !this.setToDefaults;
        this.resetFilter();
        this.filtersModifiedByUser = true;
    }
    resetFilter(): void {
        this.genericRange = {};
        this.resetDataClickEvent.emit();
    }

    addInputChange(e: any): void {
        if (e?.type === 'patientid') e.type = 'patientNum';
        if (e.range) {
            this.genericRange[e.type] = e.range;
            return;
        }
        if (e.event) this.genericRange[e.type] = e.event.value;
    }

    private separateObject = (obj: object): {}[] => {
        const result = [];
        let newObject = Object.entries(obj);
        for (const newObj of newObject) {
            let createObj = {};
            createObj[newObj[0]] = newObj[1];
            result.push(createObj);
        }

        return result;
    };

    /**
     * Dynamically create 3 columns per row based on array size
     */
    /* istanbul ignore next */
    private createColumnsForFilterControls(): void {
        this.createRows = []; //reset filters to avoid duplicates
        if (!this.selectControls || this.selectControls.length < 0) return;

        let numberOfColumns: number = 3;

        if (this.isLargeScreen()) numberOfColumns = 4;

        const isDivible = this.selectControls.length % numberOfColumns;

        if (isDivible > 0) {
            if (isDivible == numberOfColumns - 1) {
                this.selectControls.push({
                    labelText: '',
                    type: null,
                    category: '',
                    placeholderText: '',
                    values: null,
                });
            } else {
                this.selectControls = this.selectControls.concat(
                    this.generateEmptyControl(numberOfColumns - (this.selectControls.length % numberOfColumns))
                );
            }
        }

        let row = 0;
        for (let i = 0; i < this.selectControls.length / numberOfColumns; i++) {
            let nextRow = this.selectControls.slice(row, numberOfColumns + row);
            this.createRows.push({ vals: nextRow });
            row += numberOfColumns;
        }
    }

    generateEmptyControl(numberOfEmptyElements: number) {
        let emptyArray: any[] = [];

        for (let i = 0; i < numberOfEmptyElements; i++) {
            emptyArray.push({ labelText: '', type: null, category: '', placeholderText: '', values: null });
        }
        return emptyArray;
    }

    getFilterName(): string {
        if (this.isPatientAllUrl) return 'allPatientFilters';
        else if (this.isPatientOverviewUrl) {
            return 'patientFilters';
        } else if (this.isManageViewUrl) return 'accessRequestLogFilters';
        return 'requestFilters';
    }
}

<div class="o-page">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <h3>Site Overview</h3>
        </div>
        <div class="o-grid__item" *ngIf="isAddPatientButtonVisible()">
            <div class="flex end">
                <mds-button
                    [id]="'addPatientBtn'"
                    variant="primary"
                    label="Add Patient"
                    (clickEvent)="addNewPatient()"
                ></mds-button>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <medpace-info-card
                *ngIf="site; else spinner"
                [isAdmin]="isAdmin"
                [data]="site"
                type="site"
                inputCTA="ADDITIONAL SITE DETAIL"
                [contentHeaders]="infoHeaderColumns"
                [contentMapping]="infoColumnMap"
                [additionalContent]="primaryCRC"
                (navButtonClick)="onAdditionalSiteDetailsClick()"
            ></medpace-info-card>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-expansion-panel
                [panelTitle]="'Site contacts'"
                [description]="''"
                icon="account_circle"
                [attr.data-cy]="'siteCrcsExpansion'"
            >
                <div class="table-content">
                    <div class="c-table">
                        <mds-table
                            id="siteContactsTable"
                            [dataSource]="siteContactsDataSource || undefined"
                            [hasCustomRowDefs]="true"
                            columnCase="uppercase"
                            dataWhiteSpace="nowrap"
                            noDataMessage="No contacts defined"
                            matSort
                        >
                            <ng-container *ngFor="let column of siteContactsColumnMap" [matColumnDef]="column.name">
                                <mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    sortActionDescription="Sort by {{ column.name }}"
                                    >{{ column.header.toUpperCase() }}</mat-header-cell
                                >
                                <mat-cell
                                    *matCellDef="let element"
                                    mat-cell
                                    class="medpace-cell"
                                    [ngClass]="element.isPrimary ? 'text-bold' : ''"
                                >
                                    {{ element[column.name] }}
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="siteContactsColumnHeader"></mat-header-row>
                            <mat-row
                                (click)="onSiteContactClick(row)"
                                *matRowDef="let row; columns: siteContactsColumnHeader"
                            ></mat-row>
                        </mds-table>
                    </div>
                </div>
            </mds-expansion-panel>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item"></div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <medpace-table
                *ngIf="site; else spinner"
                [data]="patients"
                [columns]="tableColumnHeaders"
                isDeletedPropertyName="isPatientDeleted"
                title="Patients"
                [fieldMap]="tableColumnMap"
                searchPlaceholder="Visit Type"
                [filterOptions]="visitTypes"
                [ifFiltering]="true"
                [isSearching]="true"
                [searchFn]="searchFn"
                (rowClickEvent)="doClickRow($event)"
            ></medpace-table>
        </div>
    </div>
</div>
<ng-template #spinner>
    <div class="spinnerOverlay">
        <medpace-loading-card></medpace-loading-card>
    </div>
</ng-template>

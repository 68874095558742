import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MedpacePatientTransferToAnotherSiteComponent } from '@app/admin/manage/modals/patient-transfer-to-another-site/patient-transfer-to-another-site.component';
import { SendInvitationModalComponent } from '@app/admin/manage/modals/user-management/send-invitation-modal/send-invitation-modal.component';
import { ModalResult } from '@app/enums/modal-result.enum';
import { DisplayErrorModalComponent } from '@components/molecules/modals/display-error-modal/display-error-modal.component';
import { MedpaceMessageModalComponent } from '@components/molecules/modals/medpace-message-modal/medpace-message-modal.component';
import { SiteClintrakChangesModalComponent } from '@components/molecules/modals/site-clintrak-changes-modal/site-clintrak-changes-modal.component';
import { StudyClintrakChangesModalComponent } from '@components/molecules/modals/study-clintrak-changes-modal/study-clintrak-changes-modal.component';
import { Chip } from '@medpacesoftwaredevelopment/designsystem';
import { Patient, getFullName } from '@models/patient';
import { Site, SiteCRC } from '@models/site';
import { Study } from '@models/study';
import { AdminPatientServices } from '@services/admin/admin-patient.service';
import { ClinTrakDataService } from '@services/clintrak/clintrak-data.service';
import { FileDownloadService } from '@services/file-download/file-download.service';
import { PatientPortalService } from '@services/patient-portal/patient-portal.service';
import { AllPatientStatusesEnum } from '@services/patient-status/patient-status.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import { transformToPatchPatientDataModel } from '@services/transforms/patient-transform';
import { UserService } from '@services/user/user.service';
import { deepClone } from 'fast-json-patch';
import {
    EMPTY,
    Observable,
    ReplaySubject,
    Subject,
    catchError,
    combineLatest,
    defer,
    filter,
    finalize,
    map,
    of,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs';
@Component({
    selector: 'medpace-info-card',
    templateUrl: './info-card.component.html',
    styleUrls: ['./info-card.component.scss'],
})
export class MedpaceInfoCardComponent implements OnInit, OnChanges, OnDestroy {
    AllPatientStatusesEnum = AllPatientStatusesEnum;
    private componentDestroyed$: Subject<boolean> = new Subject();

    siteTooltipText: string = '';

    @Input()
    data: Patient | Site | Study;

    // individual getters that assume specific types, for improved intellisense in templates:
    get patientData(): Patient | null {
        return this.data as Patient;
    }
    get siteData(): Site | null {
        return this.data as Site;
    }
    get studyData(): Study | null {
        return this.data as Study;
    }

    @Input()
    inputCTA: string;

    @Input()
    isAdmin: boolean;

    @Input()
    contentHeaders: string[];

    @Input()
    contentMapping: any[];

    @Input()
    additionalContent: any;

    @Output()
    navButtonClick = new EventEmitter();

    @Input()
    type: 'site' | 'study' | 'patient';

    patientPortalChip: Chip = {
        color: 'accent',
        value: 'PatientPACE Access',
        highlighted: true,
        disableRipple: true,
        trailingIcon: 'account_circle',
        tooltip: {
            message: 'Patient has Access to PatientPACE App',
        },
    };

    clinTrakChip: Chip = {
        color: 'accent',
        value: 'ClinTrak',
        highlighted: true,
        disableRipple: true,
        trailingIcon: 'check_circle_outline',
        tooltip: {
            message: 'Patient exists in ClinTrak',
        },
    };

    url;
    inviteCRCButtonVisibility: boolean = false;
    canInvitePrimaryCRC: boolean = false;
    #fileDownloadService = inject(FileDownloadService);
    #sanitizer = inject(DomSanitizer);
    #patientStateService = inject(PatientStateService);
    #patient$ = this.#patientStateService.getPatient();
    #dialog = inject(MatDialog);
    #userService = inject(UserService);
    #clinTrakService = inject(ClinTrakDataService);
    #patientPortalService = inject(PatientPortalService);
    #patientService = inject(AdminPatientServices);
    #snackbarService = inject(SnackbarService);
    #inviteToPatientPortalSubject = new Subject<void>();
    #cancelInvitationSubject = new Subject<void>();
    #patientAccessRequestId = new ReplaySubject<string>(1);
    #router = inject(Router);
    #studyStateService = inject(StudyStateService);
    dialog = inject(MatDialog);
    #siteStateService = inject(SiteStateService);
    site$ = defer(() => {
        if (!this.patientData?.siteId) return of(null);
        this.#siteStateService.setSite(this.patientData.siteId);
        return this.#siteStateService.getSite();
    });

    #transferToAnotherSiteSubject = new Subject<void>();

    isPatientInPatientPortal$ = this.#patient$.pipe(
        switchMap((patient: Patient) => this.#patientPortalService.isPatientInPatientPortal(patient.id))
    );

    isPatientExistsInClinTrak$ = this.#patient$.pipe(
        switchMap((patient: Patient) => this.#clinTrakService.existsInClinTrak(patient.id))
    );

    getPatientAccessRequestId$ = this.#patient$.pipe(
        switchMap((patient) =>
            this.#patientPortalService.getPatientPortalAccessRequestByPatientId(patient.id).pipe(
                catchError((err) => {
                    // 404
                    this.#patientAccessRequestId.next(null);
                    return EMPTY;
                })
            )
        ),
        tap((accessRequestId) => this.#patientAccessRequestId.next(accessRequestId))
    );
    hasActiveAccessRequest$ = this.#patientAccessRequestId.pipe(map((accessRequestId) => !!accessRequestId));
    inviteToPatientPortal$ = this.#inviteToPatientPortalSubject.pipe(
        switchMap(() => this.#patient$),
        switchMap((patient) =>
            this.#dialog
                .open<MedpaceMessageModalComponent, MedpaceMessageModalComponent['data'], ModalResult>(
                    MedpaceMessageModalComponent,
                    {
                        data: {
                            title: `Invite to PatientPACE`,
                            bodyText: `Send email message with PatientPACE Invitation to Patient ${getFullName(
                                patient.patientIdentification?.firstName,
                                patient.patientIdentification?.middleName,
                                patient.patientIdentification?.lastName
                            )} at
                                ${patient.patientIdentification.emailAddress}?`,
                            showCancelButton: true,
                        },
                        width: '80%',
                        maxWidth: 600,
                        minWidth: 360,
                        disableClose: true,
                    }
                )
                .afterClosed()
                .pipe(
                    map((modalResult) => {
                        return { modalResult, patient };
                    })
                )
        ),
        filter((result) => result.modalResult === ModalResult.Okay),
        switchMap((result) =>
            this.#patientPortalService.sendEmailInvitationToPatientPortal(result.patient.id).pipe(
                catchError((err) => {
                    this.#dialog.open(DisplayErrorModalComponent, {
                        autoFocus: false,
                        width: '500px',
                        disableClose: false,
                        data: err.error.ExceptionMessage,
                    });
                    return EMPTY;
                }),
                tap((saveStatus) => {
                    const accessRequestId = saveStatus.infoLabel;
                    this.#patientAccessRequestId.next(accessRequestId);
                    this.#snackbarService.openInfoSnackbar(
                        'Email invitation has been sent successfully to the patient!'
                    );
                })
            )
        )
    );
    cancelInvitation$ = this.#cancelInvitationSubject.pipe(
        switchMap(() => this.#patient$),
        switchMap((patient) =>
            this.#dialog
                .open<MedpaceMessageModalComponent, MedpaceMessageModalComponent['data'], ModalResult>(
                    MedpaceMessageModalComponent,
                    {
                        data: {
                            title: 'Cancel Patient invitation',
                            bodyText: `Cancel active invitation to PatientPACE on Patient ${getFullName(
                                patient.patientIdentification?.firstName,
                                patient.patientIdentification?.middleName,
                                patient.patientIdentification?.lastName
                            )}`,
                            showCancelButton: true,
                        },
                        width: '80%',
                        maxWidth: 600,
                        minWidth: 360,
                        disableClose: true,
                    }
                )
                .afterClosed()
                .pipe(
                    map((modalResult) => {
                        return { modalResult, patient };
                    })
                )
        ),
        filter((result) => result.modalResult === ModalResult.Okay),
        switchMap((result) =>
            this.#patientPortalService.removePatientPortalAccessRequestByPatientId(result.patient.id).pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 404) {
                        // access request doesn't exist, probably UI is not up-to-date
                        this.#patientAccessRequestId.next(null); // update UI
                    }
                    return EMPTY;
                }),
                tap((saveStatus) => {
                    if (saveStatus.saveSuccessful) {
                        this.#snackbarService.openInfoSnackbar('Access request has been successfully canceled!');
                    } else {
                        this.#snackbarService.openInfoSnackbar('No active access requests were found!');
                    }
                    this.#patientAccessRequestId.next(null);
                })
            )
        )
    );

    transferToAnotherSite$ = this.#transferToAnotherSiteSubject.pipe(
        switchMap(() => this.#patient$.pipe(take(1))),
        switchMap((patient) =>
            this.#dialog
                .open(MedpacePatientTransferToAnotherSiteComponent, {
                    data: {
                        patient: patient,
                    },
                    width: '500px',
                    disableClose: true,
                })
                .afterClosed()
                .pipe(
                    map((modalResult) => {
                        return { modalResult, patient };
                    })
                )
        ),
        filter((result) => result.modalResult.status === ModalResult.Okay),
        switchMap((result) => {
            let patient = result.patient;
            let oldPatient = deepClone(patient);
            patient.siteId = result.modalResult.newPatientSiteId;
            return this.#patientService
                .patchPatient(
                    patient.id,
                    transformToPatchPatientDataModel(oldPatient),
                    transformToPatchPatientDataModel(patient)
                )
                .pipe(
                    tap(() => {
                        this.#router.navigateByUrl(`studies/${patient.studyId}/sites/${patient.siteId}`);
                        this.#snackbarService.openInfoSnackbar('Patient was transfered to another site');
                    }),
                    catchError((err: HttpErrorResponse) => {
                        if (err.status === 400) {
                            this.dialog.open(DisplayErrorModalComponent, {
                                autoFocus: false,
                                width: '500px',
                                disableClose: false,
                                data: err.error.ExceptionMessage,
                            });
                        } else {
                            this.dialog.open(DisplayErrorModalComponent, {
                                autoFocus: false,
                                width: '500px',
                                disableClose: false,
                                data: 'An error occurred while transferring the patient to another site.',
                            });
                        }
                        return EMPTY;
                    })
                );
        })
    );

    transferToAnotherSitePredicate$: Observable<boolean> = this.#userService.getUser().pipe(
        takeUntil(this.componentDestroyed$),
        switchMap((user) => {
            if (user.isAdmin) {
                return defer(() => {
                    this.#patientStateService
                        .getPatient()
                        .pipe(takeUntil(this.componentDestroyed$))
                        .subscribe((patient) => {
                            this.#studyStateService.setStudy(patient.studyId);
                        });

                    return this.#studyStateService.getStudy().pipe(
                        takeUntil(this.componentDestroyed$),
                        map((study) => {
                            return study?.sites?.length > 1 && this.hasAccessToDetail();
                        })
                    );
                });
            } else {
                return of(false);
            }
        })
    );

    inviteToPatientPortalClick() {
        this.#inviteToPatientPortalSubject.next();
    }

    transferToAnotherSite() {
        this.#transferToAnotherSiteSubject.next();
    }

    inviteCRCUserClick() {
        this.#dialog
            .open(SendInvitationModalComponent, {
                autoFocus: false,
                width: '500px',
                disableClose: true,
                data: {
                    canInvitePrimaryCRC: this.canInvitePrimaryCRC,
                    siteId: this.siteData?.id,
                    studyId: this.siteData?.studyId,
                },
            })
            .afterClosed()
            .pipe(
                finalize(() => {
                    this.#siteStateService.setSiteContacts();
                })
            )
            .subscribe();
    }

    cancelInvitation() {
        this.#cancelInvitationSubject.next();
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data?.currentValue?.logoUri) {
            this.SetStudyLogo(this.studyData.logoUri);
        }
    }

    ngOnInit(): void {
        if (this.studyData?.logoUri) {
            this.SetStudyLogo(this.studyData.logoUri);
        }

        if (this.type === 'site')
            combineLatest({
                userAccount: this.#userService.getCurrentUserAccountData(),
                userData: this.#userService.getUser(),
            })
                .pipe(
                    tap(({ userAccount, userData }) => {
                        const crcs: SiteCRC[] = this.siteData?.siteCRCs;
                        const isPrimaryCRC = crcs.some(
                            (x) => x.user.emailAddress == userAccount.emailAddress && x.isPrimary
                        );

                        let statusId = this.siteData?.status?.statusId;

                        this.canInvitePrimaryCRC =
                            !!statusId &&
                            statusId !== 1 &&
                            statusId !== 2 &&
                            (userData.isAdmin || userData.isSuperAdmin);

                        this.inviteCRCButtonVisibility =
                            !!statusId &&
                            statusId !== 1 &&
                            statusId !== 2 &&
                            (userData.isAdmin || userData.isSuperAdmin || isPrimaryCRC);

                        if (!userData.isAdmin && !userData.isSuperAdmin && !isPrimaryCRC) {
                            this.siteTooltipText = "Supporting CRCs can't invite other CRCs to the site";
                        } else if (!!statusId === false || statusId === 1 || statusId === 2) {
                            this.siteTooltipText = 'The button is available after site registration is completed.';
                        } else {
                            this.siteTooltipText = '';
                        }
                    }),
                    take(1)
                )
                .subscribe();
    }

    private SetStudyLogo(logoUri: string) {
        this.#fileDownloadService
            .downloadFile<Blob>(logoUri, 'blob')
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: ({ response }) => {
                    if (response instanceof HttpResponse) {
                        this.url = this.#sanitizer.bypassSecurityTrustUrl(
                            (window.URL ? URL : webkitURL).createObjectURL(response.body)
                        );
                    }
                },
            });
    }

    clickNavButton(): void {
        this.navButtonClick.emit(true);
    }

    openDialog() {
        switch (this.type) {
            case 'study':
                this.dialog.open(StudyClintrakChangesModalComponent, {
                    data: { id: this.data.id },
                    width: '75%',
                    minWidth: '700px',
                });
                break;
            case 'site':
                this.dialog.open(SiteClintrakChangesModalComponent, {
                    data: { id: this.data.id },
                    width: '75%',
                    minWidth: '700px',
                });
                break;
            default:
                break;
        }
    }

    mapFields(column: string): string {
        let val = undefined;

        this.contentMapping.forEach((el) => {
            if (this.data && el.name === column && el.isNested) {
                val = this.data[el.nest][el.field];
                return this.data[el.nest][el.field];
            }
            if (this.data && el.name === column) {
                val = this.data[el.field];
                return this.data[el.field];
            }
        });

        return val;
    }

    hasAccessToDetail(): boolean {
        return this.isAdmin || (!this.isAdmin && (this.type === 'patient' || this.type === 'site'));
    }
    canInviteToPatientPortal$ = of(this.hasAccessToDetail()).pipe(
        switchMap((hasAccessToDetail) => {
            if (hasAccessToDetail) return of(true);
            else
                return this.site$.pipe(
                    filter(Boolean),
                    map((site) => site.services?.allowPpinvitations)
                );
        })
    );

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    getLowerCaseNameWithoutSpacesAndSpecialCharacters(value: string) {
        return this.type + '-' + value.toLocaleLowerCase().replace(/\s/g, '').replace('#', '');
    }

    showPatientDetailsTooltip(patientData: Patient) {
        if (patientData.sponsor.length + patientData.protocol.length + patientData.siteNumber.length > 150) return true;

        return false;
    }

    getStudyDetailsTitle(patientData: Patient, details: string) {
        if (this.showPatientDetailsTooltip(patientData)) {
            if (details.length > 50) return details.substring(0, 47) + '...';
        }

        return details;
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { dateFormatPipe } from '@app/pipes/datepipe';
import { PatientDetailsViewModel } from '@models/patient';
import { TableColumnHeader } from '@models/table-header';
import { User } from '@models/user';
import { UserService } from '@services/user/user.service';
import { map, of, switchMap } from 'rxjs';
import { AdminPatientServices } from 'src/app/services/admin/admin-patient.service';

@Component({
    selector: '',
    templateUrl: './patient-table.component.html',
})
export class MedpacePatientTableComponent implements OnInit {
    patients: PatientDetailsViewModel[];
    countries: any[];
    columns: string[] = [
        'statusName',
        'siteNum',
        'patientNum',
        'pcsId',
        'firstName',
        'lastName',
        'lastVisitDate',

        'country',
    ];
    columnMapping: TableColumnHeader[] = [
        { header: 'status', name: 'statusName' },
        { header: 'site id', name: 'siteNum' },
        { header: 'patient id', name: 'patientNum' },
        { header: 'pcs id', name: 'pcsId' },
        { header: 'last visit date', name: 'lastVisitDate' },
        { header: 'first name', name: 'firstName' },
        { header: 'last name', name: 'lastName' },
        { header: 'country', name: 'country' },
    ];

    constructor(
        private router: Router,
        private patientServices: AdminPatientServices,
        private datePipe: dateFormatPipe,
        private activatedRoute: ActivatedRoute,
        private userService: UserService
    ) {}

    patientEditRequests$ = this.userService.getUser().pipe(
        switchMap((user) => {
            if (user.isSuperAdmin) {
                return this.patientServices.getAllSubmittedPatientEditRequests();
            } else {
                return of(undefined);
            }
        })
    );
    expandProfileEditRequests$ = this.activatedRoute.queryParams.pipe(
        map((params: Params) => {
            if (!!params?.params) {
                //params exist, parse
                const parsedParam = JSON.parse(params.params);
                if (!!parsedParam?.expandEditRequest) {
                    //expandEditRequest params exists
                    return parsedParam.expandEditRequest;
                }
            }
            return false;
        })
    );

    ngOnInit(): void {
        this.patientServices.getAllPatientSummaries().subscribe((results) => {
            results.forEach((r) => {
                if (r.firstName === 'Removed' && r.lastName === 'Removed') {
                    r.country = 'Removed';
                    r.region = 'Removed';
                }
                r.lastVisitDate = this.datePipe.transform(r.lastVisitDate)?.toUpperCase();
            });
            this.patients = results;
        });
    }

    doClickRow(event): void {
        this.router.navigate([`studies/${event.studyId}/sites/${event.siteId}/patients/${event.id}`], {
            state: event.patient,
        });
    }

    isUserAdmin() {
        return this.userService.getUser().pipe(map((user: User) => user.isSuperAdmin));
    }
    searchFn(row: any, fieldIdentifier: any, value: string) {
        if (fieldIdentifier === 'firstName' || fieldIdentifier === 'lastName') {
            const firstName = row['firstName'] as string;
            const lastname = row['lastName'] as string;
            const valueNoWhitespace = value.replace(/\s/g, '');
            const fullNameSearch = (firstName + lastname).toLowerCase().includes(valueNoWhitespace.toLowerCase());

            return fullNameSearch;
        } else {
            return row[fieldIdentifier].toString().toLowerCase().includes(value.toLowerCase());
        }
    }
}

import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
// import { PatientEditFormGroup } from '@models/form-models/patient-edit-request-form/patient-edit-request-form';
import { DialogRef } from '@angular/cdk/dialog';
import { FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalResult } from '@app/enums/modal-result.enum';
import { CountryViewModel } from '@models/country';
import { getCaregiverFullName, getPatientFullName, Patient, PatientDataModel } from '@models/patient';
import { PatientEditRequestDto } from '@models/patientEditRequestDto';
import { AdminPatientServices } from '@services/admin/admin-patient.service';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { EventService } from '@services/event/event.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { CountryStateService } from '@services/state-management/country-state.service';
import { transformToPatientDataModel } from '@services/transforms/patient-transform';
import { SaveStatusResponse } from '@utility/utility';
import { filter, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { MedpaceMessageModalComponent } from '../medpace-message-modal/medpace-message-modal.component';
import { CaregiverEditFormGroup as CaregiverEditRequestFormGroup } from './caregiver-edit-request/caregiver-edit-request.formgroup';
import { PatientEditRequestFormGroup } from './patient-edit-request/patient-edit-request.formgroup';
import { PatientTravelPrefsEditRequestFormGroup } from './patient-travel-prefs-edit-request/patient-travel-prefs-edit-request.formgroup';

export class EditRequestFormGroup extends FormGroup<{
    patient: PatientEditRequestFormGroup;
    patientTravelPrefs: PatientTravelPrefsEditRequestFormGroup;
    caregivers: FormArray<CaregiverEditRequestFormGroup>;
}> {
    constructor(
        public readonly patient: Patient,
        public readonly editRequest: PatientEditRequestDto
    ) {
        super({
            patient: new PatientEditRequestFormGroup(patient, editRequest),
            patientTravelPrefs: new PatientTravelPrefsEditRequestFormGroup(patient, editRequest),
            caregivers: new FormArray<CaregiverEditRequestFormGroup>([]),
        });
    }

    getPatientDataModel(model: PatientDataModel, countries: CountryViewModel[]) {
        this.controls.patient.getPatientDataModel(model, countries);
        this.controls.patientTravelPrefs.getPatientDataModel(model, countries);
        this.controls.caregivers.controls.forEach((control) =>
            control.getPatientDataModel(control.caregiver, countries)
        );
        return model;
    }
}

@Component({
    selector: 'app-patient-edit-request-modal',
    templateUrl: './patient-edit-request-modal.component.html',
    styleUrls: ['./patient-edit-request-modal.component.scss'],
})
export class PatientEditRequestModalComponent {
    formGroup: EditRequestFormGroup;

    patientEditRequest = inject<PatientEditRequestDto>(MAT_DIALOG_DATA);
    private countryStateService = inject(CountryStateService);
    private adminPatientServices = inject(AdminPatientServices);
    private adminSiteServices = inject(AdminSiteServices);
    private snackbarService = inject(SnackbarService);
    private router = inject(Router);
    private eventService = inject(EventService);
    private dialogRef = inject(DialogRef);
    private dialog = inject(MatDialog);
    countries$ = this.countryStateService.getCountries();
    closeModal() {
        this.dialogRef.close(null);
    }
    deleteRequest() {
        this.dialog
            .open<MedpaceMessageModalComponent, MedpaceMessageModalComponent['data'], ModalResult>(
                MedpaceMessageModalComponent,
                {
                    data: {
                        title: `Delete Patient Edit Request`,
                        bodyText: 'Are you sure you want to delete this Edit Request?',
                        showCancelButton: true,
                        okayButtonLabel: 'Yes',
                        cancelButtonLabel: 'No',
                    },
                    width: '80%',
                    maxWidth: 600,
                    minWidth: 360,
                    disableClose: true,
                }
            )
            .afterClosed()
            .pipe(
                filter((result: ModalResult) => result === ModalResult.Okay),
                switchMap((_) =>
                    this.adminPatientServices.removePatientEditRequest(this.patientEditRequest.patientId).pipe(
                        tap((result: SaveStatusResponse) => {
                            if (result.saveSuccessful) {
                                //display snackbar
                                this.snackbarService.openInfoSnackbar('Deleted Edit Request');
                                //route to patients table
                                this.router.navigate(['admin', 'patients', 'editrequest']);
                                //reload notifications to update number of edit requests
                                this.eventService.emit({ name: 'notification-reload', value: null });
                                this.dialogRef.close('remove');
                            } else throw new Error(result.errorMessage);
                        })
                    )
                )
            )
            .subscribe();
    }
    submit() {
        if (!this.formGroup.valid && !this.formGroup.disabled) {
            this.snackbarService.openErrorSnackbar('Some fields are invalid!');
            return;
        }
        this.dialog
            .open<MedpaceMessageModalComponent, MedpaceMessageModalComponent['data'], ModalResult>(
                MedpaceMessageModalComponent,
                {
                    data: {
                        title: `Save Changes`,
                        bodyText: 'Do you want to save edit request changes to Patient?',
                        showCancelButton: true,
                        okayButtonLabel: 'Yes',
                        cancelButtonLabel: 'No',
                    },
                    width: '80%',
                    maxWidth: 600,
                    minWidth: 360,
                    disableClose: true,
                }
            )
            .afterClosed()
            .pipe(
                filter((result: ModalResult) => result === ModalResult.Okay),
                switchMap(() => {
                    return this.countries$.pipe(
                        map((countries) => {
                            return this.formGroup.getPatientDataModel(
                                transformToPatientDataModel(this.formGroup.patient),
                                countries
                            );
                        }),
                        switchMap((model) =>
                            this.adminPatientServices.updatePatient(model.id, model).pipe(
                                switchMap((updatePatientSaveStatus) => {
                                    if (updatePatientSaveStatus.saveSuccessful) {
                                        //updated successfully, delete patientEditRequest
                                        return this.adminPatientServices.removePatientEditRequest(model.id).pipe(
                                            tap((removeEditRequestSaveStatus) => {
                                                if (removeEditRequestSaveStatus.saveSuccessful) {
                                                    //route to patients table
                                                    //display snackbar
                                                    this.snackbarService.openInfoSnackbar(
                                                        'Patient updated successfully'
                                                    );
                                                    this.router.navigate(['admin', 'patients', 'editrequest']);
                                                    //reload notifications to update number of edit requests
                                                    this.eventService.emit({
                                                        name: 'notification-reload',
                                                        value: null,
                                                    });
                                                    this.dialogRef.close('saved');
                                                } else throw new Error(removeEditRequestSaveStatus.errorMessage);
                                            })
                                        );
                                    }
                                    //not updated, throw error
                                    throw new Error(updatePatientSaveStatus.errorMessage);
                                })
                            )
                        )
                    );
                })
            )
            .subscribe();
    }
    isAnyControlEnabled(formGroup: FormGroup) {
        return Object.values(formGroup.controls).some((control) => control.enabled);
    }
    patient$: Observable<Patient> = this.adminPatientServices
        .getPatientTrialSummary(this.patientEditRequest.patientId)
        .pipe(
            map((patient) => {
                this.formGroup = new EditRequestFormGroup(patient, this.patientEditRequest);
                patient.caregivers.forEach((caregiver) => {
                    const caregiverEditRequest = this.patientEditRequest.caregiverEditRequests.find(
                        (caregiverEditRequest) => caregiverEditRequest.caregiverId === caregiver.id
                    );
                    if (caregiverEditRequest) {
                        this.formGroup.controls.caregivers.push(
                            new CaregiverEditRequestFormGroup(caregiver, caregiverEditRequest)
                        );
                    }
                });

                return patient;
            }),
            shareReplay(1)
        );
    getPatientFullName = getPatientFullName;
    getCaregiverFullname = getCaregiverFullName;

    isTravelSupported$ = this.patient$.pipe(
        switchMap((patient: Patient) => this.adminSiteServices.isTravelSupportedInSite(patient.siteId))
    );
}

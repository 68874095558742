import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { CreateAccessRequestDto, InvitationDto } from '@models/accessRequest';
import { ICreateAccessRequestDto } from '@models/interfaces/iAccessRequest';
import { User, UserAdminViewModel } from '@models/user';
import { AccessRequestService } from '@services/access-request/access-request.service';
import { AuthService } from '@services/auth/auth.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { UserService } from '@services/user/user.service';
import { maxLengthValidator, phoneNumberFormatValidatorWithMaxLength } from '@utility/utility.validators';
import { combineLatest, finalize, of, switchMap, take, tap } from 'rxjs';
import { AccessLevelTypes } from '../../../enums/AccessLevelTypes';
import { DisplayErrorModalComponent } from '../modals/display-error-modal/display-error-modal.component';

@Component({
    selector: 'create-access-request-card',
    templateUrl: './create-access-request-card.component.html',
    styleUrls: ['./create-access-request-card.component.scss'],
})
export class CreateAccessRequestCardComponent {
    accessFormGroup: FormGroup;
    key: string;
    isSuccess: boolean = false;
    isAuth: boolean = false;
    hasPcsAccount: boolean = false;
    activeAccount: boolean = false;
    isLoading: boolean = true;
    isFromNoRolePage: boolean = false;
    isGuidValid: boolean = true;
    invitation: InvitationDto;

    accessLevels: MdsOption[] = [
        { value: 2, viewValue: 'Admin' },
        { value: 1, viewValue: 'Super Admin' },
    ];

    vm$ = this.authService.getIsAuthenticated().pipe(
        take(1),
        tap((isAuth) => {
            this.isAuth = isAuth;
            if (!isAuth) this.initNoAuthControls(true);
        }),
        switchMap((isAuth) => {
            if (isAuth) return this.userService.getCurrentUserAccountData();
            else return of(null);
        }),
        tap((account) => {
            this.hasPcsAccount = account ? true : false;
            this.activeAccount = account?.isActive;
            if (this.isFromNoRolePage) this.initAdminAndSuperAdminControls();
            else {
                if (this.isAuth && this.activeAccount === false) {
                    this.initNoAuthControls(true);
                    this.populateFormByPcsData(account);
                }
            }
        }),
        switchMap((account) => {
            if (!account) return combineLatest([of(null), of(null)]);
            return combineLatest([this.userService.getUser(), of(account)]);
        }),
        tap(([user, account]) => {
            if (!account && user) {
                this.initNoAuthControls(true);
                this.populateFormByClaims(user);
            }
        }),
        finalize(() => (this.isLoading = false))
    );

    constructor(
        private accessRequestService: AccessRequestService,
        private formBuilder: FormBuilder,
        private snackBarSerice: SnackbarService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        public dialog: MatDialog
    ) {
        this.key = this.route.snapshot.paramMap.get('guid');
        const currentState = this.router.lastSuccessfulNavigation;
        this.isFromNoRolePage = currentState?.extras?.state?.isFromNoRole;

        this.validAndGetInvitation();
    }

    private populateFormByClaims(user: User) {
        if (this.isAuth && user) {
            this.accessFormGroup.controls.firstNameControl.patchValue(user?.firstName);
            this.accessFormGroup.controls.lastNameControl.patchValue(user?.lastName);
            this.accessFormGroup.controls.emailAddressControl.patchValue(user?.emailAddress);
            this.accessFormGroup.controls.emailAddressControl.disable();
        }
    }

    private populateFormByPcsData(account: UserAdminViewModel) {
        if (this.hasPcsAccount && account) {
            this.accessFormGroup.controls.firstNameControl.patchValue(account?.firstName);
            this.accessFormGroup.controls.lastNameControl.patchValue(account?.lastName);
            this.accessFormGroup.controls.emailAddressControl.patchValue(account?.emailAddress);
            this.accessFormGroup.controls.phoneNumberControl.patchValue(account?.phoneNumber);
            this.accessFormGroup.controls.emailAddressControl.disable();
        }
    }

    allFormVisible() {
        return (
            !this.isFromNoRolePage &&
            (!this.isAuth || (this.isAuth && !this.hasPcsAccount) || (this.isAuth && !this.activeAccount))
        );
    }

    validAndGetInvitation() {
        if (this.isFromNoRolePage) {
            this.isGuidValid = true;
            this.isLoading = false;
        } else if (!this.isFromNoRolePage && this.key) {
            this.isLoading = true;
            this.accessRequestService
                .getInvitation(this.key ?? '')
                .pipe(
                    take(1),
                    tap((invitation) => {
                        this.isGuidValid = invitation != null;
                        this.invitation = invitation;
                        if (!this.isAuth) {
                            this.accessFormGroup.controls.emailAddressControl.patchValue(this.invitation?.userEmail);
                            this.accessFormGroup.controls.emailAddressControl.disable();
                        }
                    }),
                    finalize(() => (this.isLoading = false))
                )
                .subscribe();
        }
    }

    initNoAuthControls(phoneNumberRequired: boolean) {
        this.accessFormGroup = this.formBuilder.group({
            emailAddressControl: ['', [Validators.required, maxLengthValidator(50)]],
            firstNameControl: ['', [Validators.required, maxLengthValidator(50)]],
            lastNameControl: ['', [Validators.required, maxLengthValidator(50)]],
            phoneNumberControl: [
                '',
                phoneNumberRequired
                    ? [Validators.required, phoneNumberFormatValidatorWithMaxLength(20)]
                    : [phoneNumberFormatValidatorWithMaxLength(20)],
            ],
            justificationControl: ['', maxLengthValidator(500)],
        });
    }

    initAdminAndSuperAdminControls() {
        this.accessFormGroup = this.formBuilder.group({
            justificationControl: ['', maxLengthValidator(500)],
            userAccessGroupControl: ['', Validators.required],
        });
    }

    sendRequest() {
        if (this.accessFormGroup?.invalid) {
            this.accessFormGroup.markAllAsTouched();
            return;
        }

        this.accessRequestService
            .createAccessRequest(this.createObject(), this.key ?? '')
            .pipe(take(1))
            .subscribe({
                error: (e) => {
                    console.error('error', e);
                    this.dialog.open(DisplayErrorModalComponent, {
                        autoFocus: false,
                        width: '500px',
                        disableClose: false,
                        data: e.error.ExceptionMessage,
                    });
                },
                complete: () => {
                    this.snackBarSerice.openInfoSnackbar('Request has been sent successfully.');
                    this.isSuccess = true;
                },
            });
    }

    select(event: MatSelectChange) {
        this.accessFormGroup?.controls['userAccessGroupControl']?.patchValue(event.value);
    }

    createObject(): CreateAccessRequestDto {
        return this.isAuth && this.isFromNoRolePage
            ? new CreateAccessRequestDto(<ICreateAccessRequestDto>{
                  userAccessGroupId: this.accessFormGroup.controls.userAccessGroupControl?.value,
                  justification: this.accessFormGroup.controls.justificationControl?.value,
              })
            : this.hasPcsAccount && this.activeAccount
              ? new CreateAccessRequestDto(<ICreateAccessRequestDto>{
                    userAccessGroupId: AccessLevelTypes.CRC,
                })
              : new CreateAccessRequestDto({
                    firstName: this.accessFormGroup.controls.firstNameControl.value,
                    lastName: this.accessFormGroup.controls.lastNameControl.value,
                    userEmail: this.accessFormGroup.controls.emailAddressControl.value,
                    phoneNumber: this.accessFormGroup.controls.phoneNumberControl.value,
                    justification: this.accessFormGroup.controls.justificationControl?.value,
                    userAccessGroupId: this.invitation?.accessGroupName
                        ? AccessLevelTypes[this.invitation.accessGroupName]
                        : AccessLevelTypes.NoRole,
                });
    }
}

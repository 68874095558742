/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
// TODO: Needs to investigated and fixed
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MdsPaginatorComponent } from '@medpacesoftwaredevelopment/designsystem';
import { customSort, getSortingValue } from '@models/datatable/custom-sort';
import { FilterObject } from '@models/interfaces/FilterObject';
import { utc } from 'moment';

@Component({
    selector: 'medpace-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss', './../../organisms/input-card/input-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedpaceTableComponent implements OnInit, OnChanges, AfterViewInit {
    _data: any[];
    private filterValue: string = '';

    @Input()
    title: string;

    @Input()
    set data(value: any[]) {
        this._data = value;
        this.applyFilter();
    }

    @Input()
    columns: string[];

    @Input()
    fieldMap: any[];

    @Input()
    filterOptions: any[];

    @Input()
    searchPlaceholder: string;

    @Input()
    isDeletedPropertyName: string;

    @Input()
    showFilters: boolean;

    @Input()
    ifFiltering: boolean;

    @Input()
    isSearching: boolean;

    @Input()
    isCard: boolean = true;

    @Input()
    includePaginator: boolean = true;

    @Input() defaultSorting?: {
        columnName: string;
        direction: 'asc' | 'desc' | '';
    } = undefined;

    // custom search function that can provided to customize filtering objects by typing in the search box
    @Input() searchFn?: (row: any, field: any, value: string, isFilterField: boolean) => boolean = undefined;

    @Output()
    doFilterData = new EventEmitter<any[]>();

    //matches dates in format DD-MON-YYYY, for example 20-MAY-2022
    dateRegex = new RegExp(/(\d{2,2}-[A-Za-z]{3,3}-\d{4,4})+/);
    @Output()
    rowClickEvent = new EventEmitter<any>();

    @ViewChild(MatSort, { static: true }) mySort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('tablePaginator') tablePaginator = new MdsPaginatorComponent(this.cdRef);

    filterExpanded: boolean = false;
    loadSpinner: boolean = true;
    filterableData;
    stringArrayFilter: string[];
    paginatorList: HTMLCollectionOf<Element>;
    pageSize: number = 10;
    pageSizeOptions: number[] = [10, 20, 30, 40, 50];
    colsData: string[];

    constructor(private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.stringArrayFilter = [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filterableData = new MatTableDataSource(this._data);
        this.filterableData.sortData = (data: any[], sort: MatSort) => {
            return customSort(data, sort);
        };
        if (changes.data && changes.data.currentValue) {
            this.invokeFilterPredicate();
            this.applyFilter();
            this.loadSpinner = false;

            if (this.includePaginator) {
                this.filterableData.paginator = this.tablePaginator.paginator;
            }

            this.filterableData.sortingDataAccessor = (item, property) => {
                const parsedDate = Date.parse(item[property]);

                if (!isNaN(parsedDate)) {
                    if (this.dateRegex.test(item[property].toString())) {
                        return new Date(item[property]);
                    }
                }

                const parsedNum = parseFloat(item[property]);

                if (property === 'siteNumber') {
                    return getSortingValue(item[property]);
                }

                if (!isNaN(parsedNum)) {
                    if (item[property].toString().includes('-') && property === 'patientNum') {
                        return item[property].toString().replace('-', '');
                    }
                    return parsedNum;
                }

                return item[property] ? item[property].toLowerCase() : '';
            };

            this.filterableData.sort = this.mySort;
        }
    }

    ngAfterViewInit() {
        this.filterableData.paginator = this.tablePaginator.paginator;
    }

    toggleSelect(event: any) {
        this.filterExpanded = !this.filterExpanded;
    }

    emitChange(event: any) {
        this.stringArrayFilter = [];
        event.value.forEach((element) => {
            this.stringArrayFilter.push(element);
        });
        if (this.stringArrayFilter.length == 0) {
            this.filterableData.filter = '';
        } else {
            this.filterableData.filter = <FilterObject>{
                search: this.filterValue,
                filters: [{ filterForAllColumns: this.stringArrayFilter }],
            };
        }
    }

    applyFilter(event?: Event) {
        if (event) {
            this.filterValue = (event.target as HTMLInputElement).value;
        }
        if (this.filterableData) {
            this.filterableData.filter = <FilterObject>{
                search: this.filterValue,
                filters: this.filterableData?.filter?.filters,
            };
            this.doFilterData.emit(this.filterableData.sortData(this.filterableData.filteredData, this.mySort));
        }
    }

    filterClickEvent(e: any): void {
        this.filterableData.filter = <FilterObject>{ search: this.filterValue, filters: e };
        this.filterableData.paginator = this.tablePaginator.paginator;
        this.filterableData.sort = this.mySort;
        this.doFilterData.emit(this.filterableData.sortData(this.filterableData.filteredData, this.mySort));
        this.paginatorList = document.getElementsByClassName('mat-paginator-range-label');
    }

    resetDataClickEvent(): void {
        this.filterableData = new MatTableDataSource(this._data);
        this.filterableData.paginator = this.tablePaginator.paginator;
        this.filterableData.sort = this.mySort;
        this.invokeFilterPredicate();
        this.filterableData.filter = <FilterObject>{ search: this.filterValue };

        this.doFilterData.emit(this.filterableData.sortData(this.filterableData.filteredData, this.mySort));
    }

    OnSortChange(): void {
        this.doFilterData.emit(this.filterableData.sortData(this.filterableData.filteredData, this.mySort));
    }

    OnRowClick(row: any): void {
        const isRowDisabled = this.isDeletedPropertyName && row[this.isDeletedPropertyName];
        if (!isRowDisabled) {
            this.rowClickEvent.emit(row);
        }
    }

    /** A hook allowing to override the `title` of the row (a tooltip displayed when user hovers the mouse over the row) in certain situations */
    GetTitle(row: any): string | undefined {
        const isRowDisabled = this.isDeletedPropertyName && row[this.isDeletedPropertyName];
        if (isRowDisabled) {
            return `This row cannot be accessed because related data was deleted`;
        }
        return undefined;
    }

    /**
     * @filtering is used in multiple places
     * Note: Special Dynamic filtering allows multi checkbox and data selections with and/or built-in based on each column
     */
    private invokeFilterPredicate(): void {
        this.filterableData.filterPredicate = (data: any, filter: any) => {
            //when filter search is empty or filter.fillters are not defined should return all data
            if (filter?.search == '' && filter?.filters == undefined) return true;
            const keys = Object.keys(data);

            let matchFilter = [];
            let searchMatchFilter = [];
            let count = 0;

            // when filter is set and have filters defined
            if (Array.isArray(filter?.filters)) {
                keys.forEach((d) => {
                    filter.filters?.forEach((f, i) => {
                        if (data[d]) {
                            let filterColumn = Object.keys(f).toString();
                            let filterValues = Object.values(f).toString().split(',');
                            //old implementation filter
                            if (filterColumn.indexOf('filterForAllColumns') != -1) {
                                for (const values of filterValues) {
                                    if (this.fieldContainsValue(data, data[d], values.toString(), true)) {
                                        count++;
                                        break;
                                    }
                                }
                            } else if (filterColumn.indexOf('date') == -1 && filterColumn.indexOf('timestamp') == -1) {
                                //dynamic filter (not date filter)
                                // Check for date range values based on column name
                                if (d.toLowerCase() === filterColumn.toLowerCase()) {
                                    for (const values of filterValues) {
                                        if (this.fieldContainsValue(data, d, values.toString(), true)) {
                                            count++;
                                            break;
                                        }
                                    }
                                }
                            } else {
                                //dynamic filter (date filter)
                                if (d.toLowerCase() === filterColumn.toLowerCase()) {
                                    const dateValue = utc(data[d]).toISOString();

                                    const isInRange =
                                        dateValue >= utc(filterValues[0]).startOf('D').toISOString() &&
                                        dateValue <= utc(filterValues[1]).endOf('D').toISOString();
                                    if (isInRange) {
                                        count++;
                                    }
                                }
                            }
                        }
                    });
                });

                if (count == filter.filters.length) {
                    matchFilter.push(true);
                }
            }
            //when filters are not set filter only by searchField value
            if (filter.filters == undefined) {
                keys.forEach((d) => {
                    if (data[d]) {
                        searchMatchFilter.push(this.fieldContainsValue(data, d, filter.search, false));
                    }
                });
                return searchMatchFilter.indexOf(true) != -1 ? true : false;
            } else {
                //when filters are set filter by filters and searchField
                keys.forEach((d) => {
                    if (data[d]) {
                        searchMatchFilter.push(this.fieldContainsValue(data, d, filter.search, false));
                    }
                });
                return matchFilter.indexOf(true) != -1 && searchMatchFilter.indexOf(true) != -1 ? true : false;
            }
        };
    }

    private fieldContainsValue(row: any, fieldIdentifier: any, value: string, isFilterField: boolean): boolean {
        if (this.searchFn) return this.searchFn(row, fieldIdentifier, value, isFilterField);
        else return row[fieldIdentifier].toString().toLowerCase().includes(value.toLowerCase());
    }

    setMatCellClass(element, columnName): string[] {
        let classes: string[] = [];

        if (element[columnName] === 'Removed') {
            classes.push('mat-cell-removed ');
        }
        if (columnName === 'status') {
            classes.push('padding-right-10');
        }
        return classes;
    }
}

<div class="o-page">
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h1>Sites</h1>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item" *ngIf="currentUser$ | async as currentUser">
                <div *ngIf="currentUser.isAdmin || currentUser.isSuperAdmin">
                    <medpace-table
                        *ngIf="{ adminVM: adminVM$ | async } as vm"
                        [data]="vm.adminVM?.sites === null ? undefined : vm.adminVM.sites"
                        title="All Sites"
                        [columns]="columns"
                        [fieldMap]="columnMap"
                        [isSearching]="true"
                        [showFilters]="true"
                        [searchFn]="siteSearchFn"
                        (rowClickEvent)="onRowClick($event, currentUser)"
                    ></medpace-table>
                </div>
                <div *ngIf="!currentUser.isAdmin && !currentUser.isSuperAdmin">
                    <medpace-table
                        *ngIf="{ crcVM: crcVM$ | async } as vm"
                        [data]="vm.crcVM?.sites === null ? undefined : vm.crcVM?.sites"
                        title="All Sites"
                        [columns]="columns"
                        [fieldMap]="columnMap"
                        [isSearching]="true"
                        [showFilters]="true"
                        [searchFn]="siteSearchFn"
                        (rowClickEvent)="onRowClick($event, currentUser)"
                    ></medpace-table>
                </div>
            </div>
        </div>
    </div>
</div>

import { ReimbursementTypes } from '@app/enums/ReimbursementTypes';
import { safeUTC } from '@app/extensions/moment-extensions';
import {
    fromDTO,
    toDTO,
} from '@components/molecules/forms/patient-travel-card/ground/transfer-data/transfer-data.component';
import {
    Expense,
    Expense_DTO,
    GenericRequest,
    ReimbursementRequest,
    ReimbursementRequest_DTO,
    StipendRequest,
    StipendRequest_DTO,
    TrainTravelDetails,
    TrainTravelDetailsFormModel,
    TrainTravelDetails_DTO,
    TravelRequest,
    TravelRequestCaregiver,
    TravelRequestCaregiver_DTO,
    TravelRequestFlightTravelDetail,
    TravelRequestFlightTravelDetail_DTO,
    TravelRequest_DTO,
    mapTrainTravelDetails_To_TrainTravelDetailsDTO,
} from '@models/request';
import { utc } from 'moment';

export function transformToStipendRequest(generic: GenericRequest): StipendRequest {
    const stipendRequest: StipendRequest = {
        id: generic.id,
        requestPseudoId: generic.requestPseudoId,
        patientId: generic.patientId,
        visitNameId: generic.visitDetails?.visitNameId,
        statusId: generic.status === 0 ? 2 : generic.status,
        visitStartDate: generic.visitDetails?.visitStartDate,
        apptTime: generic.visitDetails?.visitStartTime,
        apptDurationHours: generic.visitDetails?.visitEndTime,
        amount: generic.reimbursement?.totalAmount,
        currency: generic.reimbursement?.currency,
        transactionDate: generic.reimbursement?.transactionDate,
        travelerInstructions: generic.visitDetails?.travelerInstructions,
        submittingUserId: generic.submittingUserId,
        submittingUserName: generic.submittingUserName,
        completingUserId: generic.completingUserId,
        completingUserName: generic.completingUserName,
        flaggedDuplicate: generic.flaggedDuplicate,
        additionalDetails: generic.additionalDetails,
        changedRequestType: generic.reimbursement?.changedRequestType,
        createdTimestamp: undefined,
    };

    return stipendRequest;
}

export function transformStipendToGenericRequest(stipend: StipendRequest): GenericRequest {
    const genericRequest: GenericRequest = {
        id: stipend.id,
        requestPseudoId: stipend.requestPseudoId,
        type: 'payment',
        paymentType: 'stipend',
        status: stipend.statusId,
        patientId: stipend.patientId,
        patient: undefined,
        travelRequests: undefined,
        caregiver: undefined,
        isMinor: undefined,
        requestDate: stipend.createdTimestamp,
        reimbursement: {
            totalAmount: stipend.amount,
            currency: stipend.currency,
            transactionDate: stipend.transactionDate,
            type: undefined,
            expenses: undefined,
            changedRequestType: stipend.changedRequestType,
        },
        visitDetails: {
            visitName: undefined,
            visitNameId: stipend.visitNameId,
            visitStartDate: stipend.visitStartDate,
            visitEndDate: undefined,
            travelerInstructions: stipend.travelerInstructions,
            visitStartTime: stipend.apptDurationHours,
            visitEndTime: stipend.apptTime,
        },
        travelRequestCaregivers: undefined,
        submittingUserId: stipend.submittingUserId,
        submittingUserName: stipend.submittingUserName,
        completingUserId: stipend.completingUserId,
        completingUserName: stipend.completingUserName,
        flaggedDuplicate: stipend.flaggedDuplicate,
        additionalDetails: stipend.additionalDetails,
    };

    return genericRequest;
}

export function transformStipendArrayToGenericRequestArray(stipendRequests: StipendRequest[]): GenericRequest[] {
    let requests = <GenericRequest[]>[];
    stipendRequests.forEach((stipend) => {
        requests.push(transformStipendToGenericRequest(stipend));
    });
    return requests;
}

export function transformToReimbursementRequest(generic: GenericRequest): ReimbursementRequest {
    const reimbursementRequest: ReimbursementRequest = {
        id: generic.id,
        requestPseudoId: generic.requestPseudoId,
        patientId: generic.patientId,
        visitNameId: generic.visitDetails.visitNameId,
        statusId: generic.status === 0 ? 2 : generic.status,
        visitStartDate: generic.visitDetails.visitStartDate,
        apptDurationHours: generic.visitDetails.visitEndTime,
        apptTime: generic.visitDetails.visitStartTime,
        totalAmount: generic.reimbursement.totalAmount,
        totalCurrency: generic.reimbursement.currency,
        transactionDate: generic.reimbursement.transactionDate,
        reimbursementTransactions: generic.reimbursement.expenses,
        travelerInstructions: generic.visitDetails.travelerInstructions,
        submittingUserId: generic.submittingUserId,
        submittingUserName: generic.submittingUserName,
        completingUserId: generic.completingUserId,
        completingUserName: generic.completingUserName,
        flaggedDuplicate: generic.flaggedDuplicate,
        additionalDetails: generic.additionalDetails,
        changedRequestType: generic.reimbursement?.changedRequestType,
        createdTimestamp: undefined,
    };
    return reimbursementRequest;
}

export function transformReimbursementToGenericRequest(reimbursement: ReimbursementRequest): GenericRequest {
    const genericRequest: GenericRequest = {
        id: reimbursement.id,
        requestPseudoId: reimbursement.requestPseudoId,
        travelRequests: undefined,
        travelRequestCaregivers: undefined,
        isMinor: undefined,
        type: 'payment',
        paymentType: 'out-of-pocket',
        status: reimbursement.statusId,
        patientId: reimbursement.patientId,
        patient: undefined,
        caregiver: undefined,
        requestDate: reimbursement.createdTimestamp,
        reimbursement: {
            type: ReimbursementTypes.OutOfPocket,
            totalAmount: reimbursement.totalAmount,
            currency: reimbursement.totalCurrency,
            transactionDate: reimbursement.transactionDate,
            expenses: reimbursement.reimbursementTransactions,
            changedRequestType: reimbursement.changedRequestType,
        },
        visitDetails: {
            visitNameId: reimbursement.visitNameId,
            visitName: undefined,
            visitStartDate: reimbursement.visitStartDate,
            visitEndDate: undefined,
            travelerInstructions: reimbursement.travelerInstructions,
            visitEndTime: reimbursement.apptDurationHours,
            visitStartTime: reimbursement.apptTime,
        },
        submittingUserId: reimbursement.submittingUserId,
        submittingUserName: reimbursement.submittingUserName,
        completingUserId: reimbursement.completingUserId,
        completingUserName: reimbursement.completingUserName,
        flaggedDuplicate: reimbursement.flaggedDuplicate,
        additionalDetails: reimbursement.additionalDetails,
    };
    return genericRequest;
}

export function transformToReimbursementArrayToGenericRequestArray(
    reimbursements: ReimbursementRequest[]
): GenericRequest[] {
    let requests = <GenericRequest[]>[];
    reimbursements.forEach((r) => {
        requests.push(transformReimbursementToGenericRequest(r));
    });
    return requests;
}

export function transformToTravelRequest(generic: GenericRequest): TravelRequest {
    const travelRequest: TravelRequest = {
        id: generic.id,
        requestPseudoId: generic.requestPseudoId,
        patientId: generic.patientId,
        visitNameId: generic.visitDetails.visitNameId,
        visitName: generic.visitDetails.visitName,
        statusId: generic.status === 0 ? 2 : generic.status,
        visitStartDate: generic.visitDetails.visitStartDate,
        visitEndDate: generic.visitDetails.visitEndDate,
        visitStartTime: generic.visitDetails.visitStartTime,
        visitEndTime: generic.visitDetails.visitEndTime,
        travelerInstructions: generic.visitDetails.travelerInstructions,
        caregiver: generic.caregiver,
        isMinor: generic.isMinor,

        flightRequests: generic.travelRequests ? generic.travelRequests.flightRequests : '',
        trainRequests: generic.travelRequests ? generic.travelRequests.trainRequests : '',
        lodgingRequests: generic.travelRequests ? generic.travelRequests.lodgingRequests : '',
        carServiceRequests: generic.travelRequests ? generic.travelRequests.carServiceRequests : '',
        otherRequests: generic.travelRequests ? generic.travelRequests.otherRequests : '',
        rentalCarRequests: generic.travelRequests ? generic.travelRequests.rentalCarRequests : '',

        flightRequired: generic.travelRequests.needsAirTravel,
        trainRequired: generic.travelRequests.needsTrainTravel,
        carServiceRequired: generic.travelRequests.needsCar,
        lodgingRequired: generic.travelRequests.needsLodging,
        otherServiceRequired: generic.travelRequests.needsOther,
        rentalCarRequired: generic.travelRequests.needsRentalCar,

        submittingUserId: generic.submittingUserId,
        submittingUserName: generic.submittingUserName,
        completingUserId: generic.completingUserId,
        completingUserName: generic.completingUserName,
        createdTimestamp: undefined,
        flightTransactionDate: generic.travelRequests.flightTransDate,
        flightTransactionAmount: generic.travelRequests.flightTransAmount,
        flightTransactionCurrency: generic.travelRequests.flightTransCurrency,
        flightDoc: generic.travelRequests.flightDoc,
        flightAttachmentInfoId: generic.travelRequests.flightAttachmentInfoId,
        flightRequestDetailsId: generic.travelRequests.flightRequestDetailsId,
        flightRequestDetails: generic.travelRequests.flightRequestDetails,

        trainTransactionDate: generic.travelRequests.trainTransDate,
        trainTransactionAmount: generic.travelRequests.trainCost,
        trainTransactionCurrency: generic.travelRequests.trainCurrency,
        trainDoc: generic.travelRequests.trainDoc,
        trainAttachmentInfoId: generic.travelRequests.trainAttachmentInfoId,
        trainTravelDetailsForPatient: generic.travelRequests.trainTravelDetailsForPatient,

        carServiceTransactionDate: generic.travelRequests.groundTransDate,
        carServiceTransactionAmount: generic.travelRequests.groundCost,
        carServiceTransactionCurrency: generic.travelRequests.groundCurrency,
        carServiceDoc: generic.travelRequests.groundDoc,
        carServiceAttachmentInfoId: generic.travelRequests.groundAttachmentInfoId,
        travelRequestTransferData: generic.travelRequests.transferDatas,

        lodgingCheckInDate: generic.travelRequests.lodgingCheckInDate,
        lodgingCheckOutDate: generic.travelRequests.lodgingCheckOutDate,
        lodgingDateRequired: generic.travelRequests.lodgingDateRequired,
        lodgingTransactionDate: generic.travelRequests.lodgingTransDate,
        lodgingTransactionAmount: generic.travelRequests.lodgingCost,
        lodgingTransactionCurrency: generic.travelRequests.lodgingCurrency,
        lodgingDoc: generic.travelRequests.lodgingDoc,
        lodgingAttachmentInfoId: generic.travelRequests.lodgingAttachmentInfoId,

        otherTransactionDate: generic.travelRequests.otherTransDate,
        otherTransactionAmount: generic.travelRequests.otherCost,
        otherTransactionCurrency: generic.travelRequests.otherCurrency,
        otherDoc: generic.travelRequests.otherDoc,
        otherAttachmentInfoId: generic.travelRequests.otherAttachmentInfoId,

        rentalCarTransactionDate: generic.travelRequests.rentalCarDate,
        rentalCarTransactionAmount: generic.travelRequests.rentalCarCost,
        rentalCarTransactionCurrency: generic.travelRequests.rentalCarCurrency,
        rentalCarDoc: generic.travelRequests.rentalCarDoc,
        rentalCarPickUpTime: generic.travelRequests.rentalCarPickUpTime,
        rentalCarDropOffTime: generic.travelRequests.rentalCarDropOffTime,
        rentalCarPickUpDate: generic.travelRequests.rentalCarPickUpDate,
        rentalCarDropOffDate: generic.travelRequests.rentalCarDropOffDate,
        rentalCarCountry: generic.travelRequests.rentalCarCountry,
        rentalCarLocation: generic.travelRequests.rentalCarLocation,
        rentalCarAttachmentInfoId: generic.travelRequests.rentalCarAttachmentInfoId,

        flaggedDuplicate: generic.flaggedDuplicate,
        additionalDetails: generic.additionalDetails,
        travelRequestCaregivers: generic.travelRequestCaregivers,
    };

    return travelRequest;
}

export function transformTravelRequesToGenericRequest(travel: TravelRequest): GenericRequest {
    const genericRequest: GenericRequest = {
        id: travel.id,
        requestPseudoId: travel.requestPseudoId,
        type: 'travel',
        paymentType: 'NA',
        status: travel.statusId,
        patientId: travel.patientId,
        visitDetails: {
            visitNameId: travel.visitNameId,
            visitStartDate: travel.visitStartDate,
            visitEndDate: travel.visitEndDate,
            visitName: travel.visitName,
            travelerInstructions: travel.travelerInstructions,
            visitEndTime: travel.visitEndTime,
            visitStartTime: travel.visitStartTime,
        },
        patient: undefined,
        caregiver: travel.caregiver,
        isMinor: travel.isMinor,
        requestDate: travel.createdTimestamp,
        reimbursement: undefined,
        travelRequests: {
            needsAirTravel: travel.flightRequired,
            flightRequests: travel.flightRequests,
            lodgingRequests: travel.lodgingRequests,
            needsLodging: travel.lodgingRequired,
            needsOther: travel.otherServiceRequired,

            otherRequests: travel.otherRequests,

            trainRequests: travel.trainRequests,
            needsTrainTravel: travel.trainRequired,
            carServiceRequests: travel.carServiceRequests,
            needsCar: travel.carServiceRequired,

            flightTransDate: travel.flightTransactionDate,
            flightTransAmount: travel.flightTransactionAmount,
            flightTransCurrency: travel.flightTransactionCurrency,
            flightDoc: travel.flightDoc,
            flightAttachmentInfoId: travel.flightAttachmentInfoId,
            flightRequestDetailsId: travel.flightRequestDetailsId,
            flightRequestDetails: travel.flightRequestDetails,

            trainTransDate: travel.trainTransactionDate,
            trainCost: travel.trainTransactionAmount,
            trainCurrency: travel.trainTransactionCurrency,
            trainDoc: travel.trainDoc,
            trainAttachmentInfoId: travel.trainAttachmentInfoId,
            trainTravelDetailsForPatient: travel.trainTravelDetailsForPatient,

            groundTransDate: travel.carServiceTransactionDate,
            groundCost: travel.carServiceTransactionAmount,
            groundCurrency: travel.carServiceTransactionCurrency,
            groundDoc: travel.carServiceDoc,
            groundAttachmentInfoId: travel.carServiceAttachmentInfoId,
            transferDatas: travel.travelRequestTransferData,

            needsRentalCar: travel.rentalCarRequired,
            rentalCarRequests: travel.rentalCarRequests,
            rentalCarDate: travel.rentalCarTransactionDate,
            rentalCarCost: travel.rentalCarTransactionAmount,
            rentalCarCurrency: travel.rentalCarTransactionCurrency,
            rentalCarDoc: travel.rentalCarDoc,
            rentalCarPickUpTime: travel.rentalCarPickUpTime,
            rentalCarDropOffTime: travel.rentalCarDropOffTime,
            rentalCarPickUpDate: travel.rentalCarPickUpDate,
            rentalCarDropOffDate: travel.rentalCarDropOffDate,
            rentalCarCountry: travel.rentalCarCountry,
            rentalCarLocation: travel.rentalCarLocation,
            rentalCarAttachmentInfoId: travel.rentalCarAttachmentInfoId,

            lodgingCheckInDate: travel.lodgingCheckInDate,
            lodgingCheckOutDate: travel.lodgingCheckOutDate,
            lodgingDateRequired: travel.lodgingDateRequired,
            lodgingTransDate: travel.lodgingTransactionDate,
            lodgingCost: travel.lodgingTransactionAmount,
            lodgingCurrency: travel.lodgingTransactionCurrency,
            lodgingDoc: travel.lodgingDoc,
            lodgingAttachmentInfoId: travel.lodgingAttachmentInfoId,

            otherTransDate: travel.otherTransactionDate,
            otherCost: travel.otherTransactionAmount,
            otherCurrency: travel.otherTransactionCurrency,
            otherDoc: travel.otherDoc,
            otherAttachmentInfoId: travel.otherAttachmentInfoId,
        },
        submittingUserId: travel.submittingUserId,
        submittingUserName: travel.submittingUserName,
        completingUserId: travel.completingUserId,
        completingUserName: travel.completingUserName,
        flaggedDuplicate: travel.flaggedDuplicate,
        additionalDetails: travel.additionalDetails,
        travelRequestCaregivers: travel.travelRequestCaregivers,
    };
    return genericRequest;
}

export function transformTravelRequestArrayToGenericRequestArray(travelRequests: TravelRequest[]): GenericRequest[] {
    let requests = <GenericRequest[]>[];
    travelRequests.forEach((t) => requests.push(transformTravelRequesToGenericRequest(t)));
    return requests;
}

export function mapStipendRequest_DTO_To_StipendRequest(dto: StipendRequest_DTO): StipendRequest {
    if (!!dto) {
        return {
            ...dto,
            createdTimestamp: safeUTC(dto.createdTimestamp),
            transactionDate: safeUTC(dto.transactionDate),
            visitStartDate: safeUTC(dto.visitStartDate),
        };
    } else return undefined;
}

export function mapExpense_DTO_To_Expense(dto: Expense_DTO): Expense {
    return new Expense({
        ...dto,
        timestamp: safeUTC(dto.timestamp),
    });
}
export function mapExpense_To_Expense_DTO(expense: Expense): Expense_DTO {
    return new Expense_DTO({
        ...expense,
        timestamp: expense.timestamp.toISOString(),
    });
}

export function mapReimbursementRequest_DTO_To_ReimbursementRequest(
    dto: ReimbursementRequest_DTO
): ReimbursementRequest {
    if (!!dto) {
        return <ReimbursementRequest>{
            ...dto,
            reimbursementTransactions: dto.reimbursementTransactions?.map((expenseDTO) =>
                mapExpense_DTO_To_Expense(expenseDTO)
            ),
            visitStartDate: safeUTC(dto.visitStartDate),
            createdTimestamp: safeUTC(dto.createdTimestamp),
            transactionDate: safeUTC(dto.transactionDate),
        };
    } else return undefined;
}
export function mapReimbursementRequest_To_ReimbursementRequestDTO(
    request: ReimbursementRequest
): ReimbursementRequest_DTO {
    return <ReimbursementRequest_DTO>{
        ...request,
        reimbursementTransactions: request.reimbursementTransactions?.map((expense) =>
            mapExpense_To_Expense_DTO(expense)
        ),
        visitStartDate: request.visitStartDate?.toISOString(),
        createdTimestamp: request.createdTimestamp?.toISOString(),
        transactionDate: request.transactionDate?.toISOString(),
    };
}

export function mapTravelRequestFlightTravelDetail_DTO_To_TravelRequestFlightTravelDetail(
    dto: TravelRequestFlightTravelDetail_DTO
): TravelRequestFlightTravelDetail {
    return <TravelRequestFlightTravelDetail>{
        ...dto,
        departureDate: !!dto.departureDate ? utc(dto.departureDate) : undefined,
        returnDate: !!dto.returnDate ? utc(dto.returnDate) : undefined,
    };
}

export function mapTrainTravelDetails_DTO_To_TrainTravelDetails(dto: TrainTravelDetails_DTO): TrainTravelDetails {
    if (!dto) return null;
    return <TrainTravelDetails>{
        ...dto,
        departureDate: dto.departureDate ? utc(dto.departureDate) : null,
        returnDate: dto.returnDate ? utc(dto.returnDate) : null,
    };
}

export function mapTravelRequestCaregiver_DTO_To_TravelRequestCaregivers(
    dto: TravelRequestCaregiver_DTO
): TravelRequestCaregiver {
    return <TravelRequestCaregiver>{
        ...dto,
        trainTravelDetails:
            dto.trainTravelDetails && dto.trainTravelDetails.trainStationDestination // dto.trainTravelDetails may not be undefined when not needed, so I'm using origin train station (required field) to determine if train travel is required
                ? mapTrainTravelDetails_DTO_To_TrainTravelDetails(dto.trainTravelDetails)
                : null,
        flightRequestDetails: dto.flightRequestDetails
            ? mapTravelRequestFlightTravelDetail_DTO_To_TravelRequestFlightTravelDetail(dto.flightRequestDetails)
            : null,
    };
}

export function mapTrainTravelFormModelToTrainTravelDetails(
    formModel: TrainTravelDetailsFormModel
): TrainTravelDetails {
    return {
        isSameAsPatient: formModel.useIdenticalTravelPlanAsForPatient,
        trainTravelType: formModel.trainTravelType,
        trainStationOrigin: formModel.trainStationOrigin,
        trainStationDestination: formModel.trainStationDestination,
        departureDate: formModel.trainDepartureDate,
        returnDate: formModel.trainReturnDate,
        specialRequests: formModel.trainSpecialRequests,
        trainFormInLegacyMode: formModel.trainFormInLegacyMode,
    };
}
export function mapTravelRequest_To_TravelRequestDTO(request: TravelRequest): TravelRequest_DTO {
    return <TravelRequest_DTO>{
        ...request,
        visitStartDate: request.visitStartDate?.toISOString(),
        visitEndDate: request.visitEndDate?.toISOString(),
        flightTransactionDate: request.flightTransactionDate?.toISOString(),
        trainTransactionDate: request.trainTransactionDate?.toISOString(),
        lodgingTransactionDate: request.lodgingTransactionDate?.toISOString(),
        lodgingCheckInDate: request.lodgingCheckInDate?.toISOString(),
        lodgingCheckOutDate: request.lodgingCheckOutDate?.toISOString(),
        carServiceTransactionDate: request.carServiceTransactionDate?.toISOString(),
        otherTransactionDate: request.otherTransactionDate?.toISOString(),
        trainTravelDetailsForPatient: mapTrainTravelDetails_To_TrainTravelDetailsDTO(
            request.trainTravelDetailsForPatient
        ),

        rentalCarDropOffDate: request.rentalCarDropOffDate?.toISOString(),
        rentalCarPickUpDate: request.rentalCarPickUpDate?.toISOString(),
        rentalCarTransactionDate: request.rentalCarTransactionDate?.toISOString(),

        createdTimestamp: request.createdTimestamp?.toISOString(),
        travelRequestCaregivers: request.travelRequestCaregivers.map((caregiver) =>
            mapTravelRequestCaregiver_To_TravelRequestCaregiversDTO(caregiver)
        ),
        flightRequestDetails: request.flightRequestDetails
            ? mapTravelRequestFlightTravelDetail_To_TravelRequestFlightTravelDetailDTO(request.flightRequestDetails)
            : null,

        travelRequestTransferData: request.travelRequestTransferData.map((transfer) => toDTO(transfer)),
    };
}
export function mapTravelRequest_DTO_To_TravelRequest(dto: TravelRequest_DTO): TravelRequest {
    return <TravelRequest>{
        ...dto,
        visitStartDate: safeUTC(dto.visitStartDate),
        visitEndDate: safeUTC(dto.visitEndDate),
        flightTransactionDate: safeUTC(dto.flightTransactionDate),
        trainTransactionDate: safeUTC(dto.trainTransactionDate),
        lodgingTransactionDate: safeUTC(dto.lodgingTransactionDate),
        lodgingCheckInDate: !!dto.lodgingCheckInDate ? safeUTC(dto.lodgingCheckInDate) : null,
        lodgingCheckOutDate: !!dto.lodgingCheckOutDate ? safeUTC(dto.lodgingCheckOutDate) : null,
        carServiceTransactionDate: safeUTC(dto.carServiceTransactionDate),
        otherTransactionDate: safeUTC(dto.otherTransactionDate),
        trainTravelDetailsForPatient: mapTrainTravelDetails_DTO_To_TrainTravelDetails(dto.trainTravelDetailsForPatient),

        rentalCarDropOffDate: safeUTC(dto.rentalCarDropOffDate),
        rentalCarPickUpDate: safeUTC(dto.rentalCarPickUpDate),
        rentalCarTransactionDate: safeUTC(dto.rentalCarTransactionDate),

        createdTimestamp: safeUTC(dto.createdTimestamp),
        travelRequestCaregivers: dto.travelRequestCaregivers?.map((dto) =>
            mapTravelRequestCaregiver_DTO_To_TravelRequestCaregivers(dto)
        ),
        flightRequestDetails: dto.flightRequestDetails
            ? mapTravelRequestFlightTravelDetail_DTO_To_TravelRequestFlightTravelDetail(dto.flightRequestDetails)
            : null,

        travelRequestTransferData: dto.travelRequestTransferData?.map((dto) => fromDTO(dto)),
    };
}
export function mapTravelRequestFlightTravelDetail_To_TravelRequestFlightTravelDetailDTO(
    value: TravelRequestFlightTravelDetail
): TravelRequestFlightTravelDetail_DTO {
    return <TravelRequestFlightTravelDetail_DTO>{
        ...value,
        departureDate: !!value.departureDate ? value.departureDate.toISOString() : undefined,
        returnDate: !!value.returnDate ? value.returnDate.toISOString() : undefined,
    };
}
export function mapTravelRequestCaregiver_To_TravelRequestCaregiversDTO(
    value: TravelRequestCaregiver
): TravelRequestCaregiver_DTO {
    return <TravelRequestCaregiver_DTO>{
        ...value,
        trainTravelDetails: mapTrainTravelDetails_To_TrainTravelDetailsDTO(value.trainTravelDetails),
        flightRequestDetails: value.flightRequestDetails
            ? mapTravelRequestFlightTravelDetail_To_TravelRequestFlightTravelDetailDTO(value.flightRequestDetails)
            : null,
    };
}
